import { useState, useEffect } from "react";
import NotificationRowCard from "./cards/NotificationRowCard";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth";

const sortNotificationsByDate = (notifications) => {
  const sortedNotifications = notifications.slice();

  sortedNotifications.sort((a, b) => {
    const dateA = moment(a.date_time.split(" ")[0]);
    const dateB = moment(b.date_time.split(" ")[0]);

    return dateB - dateA;
  });

  return sortedNotifications;
};

const groupNotificationsByDate = (notifications) => {
  const groupedNotifications = {};

  for (const notification of notifications) {
    const dateTimeParts = notification.date_time.split(" ");
    const date = dateTimeParts[0];
    if (!groupedNotifications[date]) {
      groupedNotifications[date] = [];
      groupedNotifications[date].push(
        moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
    }
    groupedNotifications[date].push(notification);
  }
  return groupedNotifications;
};

const ExpertNotificationsDB = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [groupedNotifications, setGroupedNotifications] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();

  // useEffect(() => {
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     window.location.href = "https://www.unitedwecare.com/";
  //   }
  // }, [location, navigate]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const payload = {
      user_id: currentUser?.UserId,
    };

    const postNotificationsData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/notifications`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const data = await postResponse.data?.notifications;
        const sortedData = await sortNotificationsByDate(data);
        const groupedData = groupNotificationsByDate(sortedData);

        setNotifications(data);
        setGroupedNotifications(groupedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      }
    };

    postNotificationsData();
  }, [selectedTab]);

  const handleTabSelect = (e) => {
    setSelectedTab(e.target.innerText);
  };

  return (
    <div className="my-3 ExpertNotificationsDashboard">
      <div className="ExpertNotificationsDashboard--Header">
        <h2>Notifications</h2>

        <div className="d-flex gap-3 Header--Options">
          <div
            className={`Option--Item ${
              selectedTab === "All" ? "SelectedItem" : ""
            }`}
            onClick={(e) => handleTabSelect(e)}
          >
            All
          </div>
          <div
            className={`Option--Item ${
              selectedTab === "Appointments" ? "SelectedItem" : ""
            }`}
            onClick={(e) => handleTabSelect(e)}
          >
            Appointments
          </div>
          <div
            className={`Option--Item ${
              selectedTab === "Review" ? "SelectedItem" : ""
            }`}
            onClick={(e) => handleTabSelect(e)}
          >
            Review
          </div>
        </div>
      </div>

      <div className="ExpertNotificationsDashboard--Content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              margin: "10px 0px 5px 10px",
              paddingTop: "10px",
            }}
          >
            {Array.from(new Array(10)).map((_, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Skeleton variant="circular" width={40} height={40} />
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "52%" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "25%" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : notifications === undefined ||
          notifications === null ||
          notifications?.length === 0 ? (
          <div className="NoDataAvailable">
            <img src="/assets/img/no-worksheets.png" />
            <p>No Notifications Found</p>
          </div>
        ) : (
          Object.values(groupedNotifications)?.map((item, index) => {
            const filteredItems = item.slice(1).filter((el) => {
              if (selectedTab === "All") {
                return true;
              } else if (selectedTab === "Review") {
                return el.type === "review";
              } else if (selectedTab === "Appointments") {
                return el.type === "appointment";
              } else if (selectedTab === "Clients") {
                return el.type !== "appointment" && el.type !== "review";
              }
              return false;
            });

            return (
              <div className="Content--Item" key={index}>
                {filteredItems.length > 0 && (
                  <h6>
                    {moment(item[0], "DD-MM-YYYY[T]HH:mm:ss z").format(
                      "D MMM YYYY"
                    )}
                  </h6>
                )}
                <div>
                  {filteredItems?.map((el) => {
                    return (
                      <NotificationRowCard
                        key={el.id}
                        image={el.image}
                        title={el.title}
                        clientID={el.uwc_user_id}
                        appointmentID={el.appointment_id}
                        type={el.type}
                        description={el.description}
                        day={el.day}
                        time={el.time}
                        clientType={el.client_type}
                        session={el.session}
                        joinNow={el.join_btn}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ExpertNotificationsDB;
