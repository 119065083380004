import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";
import { getRecommendedPrograms } from "../../_request";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

function RecommendedPrograms() {
  const navigate = useNavigate();

  const [recommendedFilter, setRecommendedFilter] = useState("Recomended");
  const [recommendedPrograms, setRecommendedPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendedPrograms = async () => {
      let body;

      if (recommendedFilter === "Recomended") {
        body = {
          version: "v3",
        };
      } else if (recommendedFilter === "Mental Health") {
        body = {
          course_type: "mental_health",
          version: "v3",
        };
      } else {
        body = {
          course_type: "wellness",
          version: "v3",
        };
      }

      try {
        const { courses } = await getRecommendedPrograms(JSON.stringify(body));
        setRecommendedPrograms(courses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching random experts:", error);
      }
    };

    fetchRecommendedPrograms();
  }, [recommendedFilter]);

  return (
    <div className="my-3">
      <div
        className="w-100"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          boxShadow: " 2px 2px 9px 14px rgba(0,0,0,0.0)",
        }}
      >
      {
        loading ? (
          <div className="d-flex justify-content-between pe-2">
          <div className="w-100">
            <div className="d-flex align-items-center pt-3 px-3">
              <Skeleton variant="text" width="100px" height="25px" className="rounded rounded-3"/>
              <div
                className="px-3 ms-auto d-flex align-items-center"
                style={{
                  padding: "0 7.282px 5.097px 11px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/program");
                }}
              >
                <Skeleton variant="text" width="100px" height="25px" className="rounded rounded-3" />
              </div>
            </div>

            <div
              className="d-flex gap-2 px-4 pt-3"
              style={{ overflowX: "auto" }}
            >
              <Skeleton variant="rounded" width="150px" height="25px" className="rounded-pill"/>
              <Skeleton variant="rounded" width="150px" height="25px" className="rounded-pill"/>
              <Skeleton variant="rounded" width="150px" height="25px" className="rounded-pill"/>
            </div>
          </div>
        </div>
        ): (
          <div className="d-flex justify-content-between pe-2">
          <div className="w-100">
            <div className="d-flex align-items-center pt-3">
              <h5 className="px-3 ms-3" style={{ fontWeight: "bold" }}>
                Explore Programs
              </h5>
              <div
                className="px-3 ms-auto d-flex align-items-center"
                style={{
                  padding: "0 7.282px 5.097px 11px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/program");
                }}
              >
                <h6
                  className="text-muted m-0 p-0 me-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  View All
                </h6>
                <BsArrowRightCircle size={25} className="arrow-hidden" />
              </div>
            </div>

            <div
              className="d-flex gap-2 px-4 py-3"
              style={{ overflowX: "auto" }}
            >
              <span
                className={`badge rounded-pill bg-light px-3 py-2 ${
                  recommendedFilter !== "Recomended" ? "text-muted" : ""
                }`}
                style={{
                  color: "#73589B",
                  cursor: "pointer",
                  "@media (max-width:600px)": {
                    width: "143px",
                    height: "52px",
                  },
                }}
                onClick={() => {
                  setRecommendedFilter("Recomended");
                }}
              >
                Recommended Programs
              </span>

              <span
                className={`badge rounded-pill bg-light px-3 py-2 ${
                  recommendedFilter !== "Mental Health" ? "text-muted" : ""
                }`}
                style={{ color: "#73589B", cursor: "pointer" }}
                onClick={() => {
                  setRecommendedFilter("Mental Health");
                }}
              >
                Mental Health Programs
              </span>

              <span
                className={`badge rounded-pill bg-light px-3 py-2 ${
                  recommendedFilter !== "Wellness" ? "text-muted" : ""
                }`}
                style={{ color: "#73589B", cursor: "pointer" }}
                onClick={() => {
                  setRecommendedFilter("Wellness");
                }}
              >
                Wellness Programs
              </span>
            </div>
          </div>
        </div>
        )
      }
        

        <div className="row main-card p-md-4 pt-md-0">
          <div className="col-md-12">
            <div className="">
              <div className="scroll-container d-flex vh-auto pt-4 pb-4">
                {loading ? (
                  <Grid container spacing={2}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "15px",
                        width: "100%",
                      }}
                    >
                      {Array.from(new Array(3)).map((_, index) => (
                        <Grid item key={index} sx={{ minWidth: "100%" }}>
                          <Skeleton variant="rounded" height={192} width="100%" className="rounded rounded-3"/>
                        </Grid>
                      ))}
                    </div>
                  </Grid>
                ) : (
                  <>
                    {recommendedPrograms?.map((item) => (
                      <div
                        key={item.id}
                        className="card bg-primary"
                        style={{
                          position: "relative",
                          minWidth: "340px",
                          width: "340px",
                          height: "192px",
                          cursor: "pointer",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "column",
                          border: "none",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          navigate(`/program/${item.name}/${item.id}`);
                        }}
                      >
                        <img
                          alt=""
                          src={item.image}
                          className="img-fluid card-img-top"
                          style={{ borderRadius: "20px", height: "100%" }}
                          loading="lazy"
                        />
                        <div
                          className="card-img-overlay "
                          style={{
                            background:
                              "linear-gradient(13deg, rgba(0, 0, 0, 0.69) 17.59%, rgba(0, 0, 0, 0.00) 83.46%)",
                            minWidth: "340px",
                            width: "340px",
                            height: "192px",
                            borderRadius: "20px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 11,
                              right: 11,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="badge rounded-pill d-flex align-items-center"
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.316)",
                                cursor: "pointer",
                                padding: "6px 7.28px 5px 11px",
                              }}
                            >
                              View Details{" "}
                              <BsArrowRight
                                size={15}
                                className="mx-1 arrow-hidden"
                              />
                            </span>
                          </div>

                          <div>
                            <div className="text-start  pt-2 align-self-end">
                              <span
                                className="card-title text-light "
                                style={{ fontSize: "1.2rem" }}
                              >
                                {item.type}
                              </span>
                              <p
                                className="card-text text-light fw-bold"
                                style={{
                                  fontSize: "1.1rem",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendedPrograms;