import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TicketDetailsTableRow from "./tables/TicketDetailsTableRow";
import RaiseTicketModal from "./modals/RaiseTicketModal";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth";

const ExpertHelpCenter = () => {
  const [ticketsData, setTicketsData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All Tickets");
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  // Check if user is logged in
  // useEffect(() => {
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     window.location.href = "https://www.unitedwecare.com/";
  //   }
  // }, [location, navigate]);

  //Get all existing tickets
  useEffect(() => {
    const payload = {
      user_id: currentUser?.UserId,
    };

    const postTicketsData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/tickets`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const data = await postResponse.data.tickets;

        setTicketsData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postTicketsData();
  }, []);

  const handletabSelect = (event) => {
    setSelectedTab(event.target.innerText);
  };

  const filteredTickets = ticketsData.filter((item) => {
    if (selectedTab === "All Tickets") {
      return true;
    } else if (selectedTab === "Resolved") {
      return item.status === "Resolved";
    } else if (selectedTab === "Ongoing") {
      return item.status !== "Resolved";
    }
    return false;
  });

  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header">
        <h2>Tickets</h2>
      </div>
      <div className="d-flex ExpertHelpCenter--Options align-items-center mt-3">
        <div className="d-flex gap-3 Options">
          <div
            className={`Option--Item ${
              selectedTab == "All Tickets" && "SelectedItem"
            }`}
            onClick={(e) => handletabSelect(e)}
          >
            All Tickets
          </div>
          <div
            className={`Option--Item ${
              selectedTab == "Resolved" && "SelectedItem"
            }`}
            onClick={(e) => handletabSelect(e)}
          >
            Resolved
          </div>
          <div
            className={`Option--Item ${
              selectedTab == "Ongoing" && "SelectedItem"
            }`}
            onClick={(e) => handletabSelect(e)}
          >
            Ongoing
          </div>
        </div>

        <RaiseTicketModal />
      </div>

      <div className="ExpertHelpCenter--Content mt-3 padding-3">
        {isLoading ? (
          <TableContainer sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Detail</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(new Array(10)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "5vw" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "10vw" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "40vw" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "5vw" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Detail</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTickets.length === 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <div className="NoDataAvailable">
                        <img src="/assets/img/no-worksheets.png" />
                        <p>No Tickets Available</p>
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  filteredTickets.map((item) => (
                    <TicketDetailsTableRow
                      key={item.serial_no}
                      ticketID={item.serial_no}
                      selectedTab={selectedTab}
                      date={item.date}
                      detail={item.detail}
                      serialNO={item.serial_no}
                      topic={item.topic}
                      status={item.status}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ExpertHelpCenter;
